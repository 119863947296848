
import { Component, Vue } from 'vue-property-decorator'
import TheContacts from '@/components/contact/TheContacts.vue'

@Component ({
  components: {
    TheContacts
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class ContactsPage extends Vue {

}

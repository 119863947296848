
import "@/assets/styles/imports/contacts.sass"
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TheContacts extends Vue {
  list = [
    { name: 'help', mail: 'support@ecudtc.com' },
    { name: 'partnership', mail: 'partner@ecudtc.com' },
    { name: 'sales', mail: 'sales@ecudtc.com' }
 ]

  getMailLink (mail: string): string {
    return `mailto:${mail}`
  }
}
